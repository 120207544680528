<template>
  <v-app>
    <v-main class="green lighten-5">
      <v-container class="py-12">
        <v-row justify="center" class="text-center mb-12">
          <v-col cols="12">
            <h1 class="text-h2 font-weight-bold green--text text--darken-3 mb-4">
              Tatu na Grota
            </h1>
            <p class="text-h5 green--text text--darken-1 mb-8">
              Produtos naturais direto da roça para sua mesa
            </p>
            <v-chip
              class="pa-4 yellow darken-1"
              large
            >
              <v-icon left>mdi-clock-outline</v-icon>
              Em breve disponível para pedidos!
            </v-chip>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            v-for="(product, index) in products"
            :key="index"
            cols="12"
            sm="6"
            md="4"
          >
            <v-card class="mx-auto" max-width="400">
              <v-img
                :src="product.image"
                height="200px"
              ></v-img>
              <v-card-title class="green--text text--darken-3">
                {{ product.name }}
              </v-card-title>
              <v-card-text class="green--text">
                {{ product.description }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row justify="center" class="mt-12">
          <v-col cols="12" md="8" lg="6" class="text-center">
            <h2 class="text-h4 font-weight-bold green--text text--darken-3 mb-4">
              Fique por dentro!
            </h2>
            <p class="text-h6 green--text mb-8">
              Deixe seu e-mail para receber novidades e saber quando estaremos abertos para pedidos.
            </p>
            <v-form @submit.prevent="submitForm">
              <v-text-field
                v-model="email"
                label="Seu melhor e-mail"
                outlined
                dense
                type="email"
                required
              ></v-text-field>
              <v-btn
                type="submit"
                color="green"
                dark
                large
                class="mt-4"
              >
                Inscrever-se
              </v-btn>
            </v-form>
          </v-col>
        </v-row>

        <v-footer class="mt-12 text-center green--text justify-center" color="transparent">
          <p>&copy; {{ new Date().getFullYear() }} Tatu na Grota. Todos os direitos reservados.</p>
        </v-footer>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'TatuNaGrotaLanding',
  data() {
    return {
      email: '',
      products: [
        {
          name: "Nibs de Cacau",
          description: "Pedacinhos crocantes de cacau puro, rico em antioxidantes.",
          image: "https://www.assai.com.br/sites/default/files/assai_atacadista_-_como_se_faz_chocolate.jpg"
        },
        {
          name: "Ovos Caipiras",
          description: "Ovos frescos de galinhas criadas soltas, com gema laranja vibrante.",
          image: "https://ibassets.com.br/ib.store.blog/blog-c3679e24678e40f28ebefe6c2fb286bc.jpeg"
        },
        {
          name: "Galinha Caipira",
          description: "Carne saborosa e firme de galinhas criadas em liberdade.",
          image: "https://images.tcdn.com.br/img/img_prod/1321487/galinha_caipira_161_1_fdbec689b191670c8cd3b305dc04fbb0.png"
        },
        {
          name: "Mel de Abelha",
          description: "Mel puro e natural, colhido de abelhas silvestres da região.",
          image: "https://c.files.bbci.co.uk/76F0/production/_126184403_gettyimages-162847094.jpg"
        },
        {
          name: "Cogumelo Shimeji",
          description: "Cogumelos frescos e saborosos, cultivados organicamente.",
          image: "https://www.quitandatomio.com.br/upload/495015232-cogumelo-shimeji-e-seus-beneficios.jpg"
        },
        {
          name: "Mandioca Orgânica",
          description: "Raiz de Mandioca fresca e versátil, cultivada sem agrotóxicos.",
          image: "https://s1.static.brasilescola.uol.com.br/be/2020/12/raiz-de-mandioca.jpg"
        }
      ]
    }
  },
  methods: {
    submitForm() {
      // Aqui você implementaria a lógica para enviar o e-mail para seu backend
      console.log('E-mail submetido:', this.email);
      // Limpar o campo de e-mail após o envio
      this.email = '';
      // Você poderia adicionar um alerta ou notificação de sucesso aqui
    }
  }
}
</script>

<style scoped>
/* Estilos adicionais podem ser adicionados aqui se necessário */
</style>